<template>
  <div>
    <div class="borderColor my-2">
      <div class="otherTableStyle overflow-auto">
        <table class="w-100 text-center font-weight-bold dataTable">
          <tr>
            <th>
              <i
                class="far fa-question-circle text-danger mr-2"
                style="font-size: 20px; cursor: pointer"
                @click="showMonitorModal"
              />計費期間
            </th>
            <th></th>
            <th>電費單度數 (kWh)</th>
            <th>監控度數 (kWh)</th>
            <th>差異 (%)</th>
            <th></th>
          </tr>
          <tr>
            <td>
              <date-picker
                v-model="lastDayInput"
                type="date"
                placeholder="本次抄表日"
                valueType="format"
                format="YYYY-MM-DD"
                style="width: 150px"
                :disabled-date="disabledDateAfterToday"
              />
            </td>
            <td>
              <date-picker
                v-model="nextDayInput"
                type="date"
                placeholder="下次抄表日"
                valueType="format"
                format="YYYY-MM-DD"
                style="width: 150px"
                :disabled-date="disabledDateAfterToday"
              />
            </td>
            <td><input v-model="kWhInput" class="text-center" /></td>
            <td>-</td>
            <td>-</td>
            <td>
              <button class="confirmButton" @click="monitorDataPush">
                加入
              </button>
            </td>
          </tr>
          <tr v-for="(i, index) in monitorData" :key="index">
            <td>{{ i.dateStart }}</td>
            <td>{{ i.dateEnd }}</td>
            <td>{{ i.genKwh }}</td>
            <td>
              {{
                dbDataHandle(i.kwhSum) === 0 ? "計算中" : dbDataHandle(i.kwhSum)
              }}
            </td>
            <td>
              {{
                dbDataHandle(i.diffRate) === 0
                  ? "計算中"
                  : dbDataHandle(i.diffRate)
              }}
            </td>
            <td>
              <button
                class="deleteButton"
                @click="checkDeleteMonitorData(index)"
              >
                刪除
              </button>
            </td>
          </tr>
        </table>
      </div>
      <div class="my-3">
        <div class="d-flex align-items-center m-3">
          <p class="font-weight-bold highChartTitle">監控值與電費單比較</p>
          <button
            class="confirmButton ml-auto"
            style="width: 120px"
            @click="downloadAnalysis"
          >
            資料下載
          </button>
        </div>
        <div v-if="hasNoData" class="text-center" style="line-height: 250px">
          尚無資料
        </div>
        <highcharts
          v-else
          ref="chart"
          :options="monitorChart"
          class="bg-white"
        />
      </div>
    </div>
    <b-modal id="monitorModal" size="lg" title="電費單說明" ok-only>
      <div class="text-center">
        <img src="../../assets/monitorPicture.png" width="700px" />
      </div>
    </b-modal>
  </div>
</template>

<script>
import downloadCsv from 'download-csv'
import { BModal } from 'bootstrap-vue'
import { DateTime } from 'luxon'
import { dbDataHandle } from '../../store/function/common/index'
import { getBillsApi, getWriteBillsApi } from '../../api'
import { disabledDateAfterToday } from '@/utils/datetime'
export default {
  name: 'Monitor',
  components: {
    BModal
  },
  props: {
    filename: {
      type: String,
      default: '下載檔案'
    }
  },
  data () {
    return {
      hasNoData: false,
      changeMonitoringIndex: null,
      lastDayInput: DateTime.local()
        .setZone('Asia/Taipei')
        .toFormat('yyyy-MM-01'),
      nextDayInput: DateTime.local()
        .setZone('Asia/Taipei')
        .toFormat('yyyy-MM-dd'),
      kWhInput: 900,
      monitorData: [],
      monitorChart: {
        chart: {
          height: 350,
          backgroundColor: null,
          events: {
            load () {
              this.showLoading()
            }
          }
        },
        credits: {
          enabled: false
        },
        title: {
          text: ''
        },
        xAxis: {
          categories: []
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true
            }
          }
        },
        legend: {
          layout: 'horizontal',
          align: 'center',
          verticalAlign: 'top',
          floating: false
        },
        yAxis: [
          {
            title: {
              text: '度數 (kWh)'
            }
          },
          {
            title: {
              text: '差異 (%)'
            },
            opposite: true
            // max: 0.13
          }
        ],
        tooltip: {
          shared: true
        },
        series: [
          {
            color: 'rgb(124,181,236)',
            type: 'column',
            name: '監控度數 (kWh)',
            data: [],
            yAxis: 0
          },
          {
            color: 'rgb(31, 209, 150)',
            type: 'column',
            name: '電費單度數 (kWh)',
            data: [],
            yAxis: 0
          },
          {
            color: 'rgb(255, 192, 0)',
            name: '差異 (%)',
            data: [],
            yAxis: 1
          }
        ],
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500
              },
              chartOptions: {
                legend: {
                  layout: 'horizontal',
                  align: 'center',
                  verticalAlign: 'bottom',
                  y: 0
                }
              }
            }
          ]
        }
      },
      monitorDataForExcel: []
    }
  },
  methods: {
    disabledDateAfterToday,
    dbDataHandle,
    checkDeleteMonitorData (index) {
      this.$swal
        .fire({
          title: `確定要刪除?`,
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: `確定`,
          denyButtonText: `否`
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.monitorDataSplice(index)
          }
        })
    },
    async monitorDataSplice (index) {
      this.monitorData.splice(index, 1)
      await this.writebills()
    },
    async monitorDataPush () {
      if (
        this.lastDayInput !== '' &&
        this.nextDayInput !== '' &&
        this.kWhInput !== '' &&
        this.nextDayInput > this.lastDayInput
      ) {
        const obj = {
          dateStart: this.lastDayInput,
          dateEnd: this.nextDayInput,
          genKwh: Number(this.kWhInput)
        }
        this.monitorData.push(obj)
        await this.writebills()
      } else {
        this.$swal('請確認日期')
      }
    },
    renderData () {
      this.monitorChart.xAxis.categories = []
      this.monitorChart.series.forEach((x) => {
        x.data = []
      })
      this.monitorData.forEach((x) => {
        this.monitorChart.xAxis.categories.push(
          `${x.dateStart} ~ ${x.dateEnd}`
        )
        // 圖表、表格 是共用 this.monitorData 資料，直接 dbDataHandle 改變原始資料，顯示處理過的數據
        x.kwhSum = x.kwhSum === null ? null : dbDataHandle(x.kwhSum)
        x.genKwh = x.genKwh === null ? null : dbDataHandle(x.genKwh)
        x.diffRate = x.diffRate === null ? null : dbDataHandle(x.diffRate)
        this.monitorChart.series[0].data.push(x.kwhSum)
        this.monitorChart.series[1].data.push(x.genKwh)
        this.monitorChart.series[2].data.push(x.diffRate)
      })
      this.hideHighChartLoading()
    },
    hideHighChartLoading () {
      this.$nextTick(function () {
        if (this.$refs && this.$refs.chart) {
          this.$refs.chart.chart.hideLoading()
        }
      })
    },
    downloadAnalysis () {
      const downloadArr = []
      let fields = ['name']
      fields = fields.concat(this.monitorChart.xAxis.categories)
      this.monitorChart.series.forEach((x) => {
        downloadArr.push({ name: x.name })
      })

      const download = []
      this.monitorChart.series.forEach((_) => {
        download.push({ data: [] })
      })

      this.monitorDataForExcel.forEach((obj) => {
        const kwhSum = obj.kwhSum === null ? null : obj.kwhSum
        const genKwh = obj.genKwh === null ? null : obj.genKwh
        const diffRate = obj.diffRate === null ? null : obj.diffRate
        download[0].data.push(kwhSum)
        download[1].data.push(genKwh)
        download[2].data.push(diffRate)
      })

      this.monitorChart.xAxis.categories.forEach((x, xindex) => {
        downloadArr.forEach((y, yindex) => {
          y[`${x}`] = download[yindex].data[xindex]
        })
      })
      downloadCsv(downloadArr, fields, this.filename)
    },
    showMonitorModal () {
      this.$bvModal.show('monitorModal')
    },
    async getbills () {
      this.monitorChart.series.forEach((x) => {
        x.data = []
      })
      const monitorData = await getBillsApi(
        this.$router.history.current.query.fid,
        this.$router.history.current.query.siteName
      )
      if (monitorData?.data?.data?.length) {
        // 複製 monitoringData、monitorDataForExcel 不能 refer 同個 data
        // renderData() 會改動 monitorData 資料

        // 下載資料用
        this.monitorDataForExcel = monitorData.data.data.map((item) => {
          const copy = Object.assign({}, item, {
            diffRate: dbDataHandle(
              (Math.abs(item.kwhSum - item.genKwh) / item.kwhSum) * 100
            )
          })
          return copy
        })

        // 顯示圖表、表格用，copy 避免改動到 rawData (monitorData.data.data)
        this.monitorData = monitorData.data.data.map((item) => {
          const copy = Object.assign({}, item, {
            diffRate: dbDataHandle(
              (Math.abs(item.kwhSum - item.genKwh) / item.kwhSum) * 100
            )
          })
          return copy
        })

        this.renderData()

        this.hasNoData = false
      } else {
        this.hasNoData = true
      }
    },
    async writebills () {
      const data = this.monitorData
        .map((x) => {
          return {
            dateStart: x.dateStart,
            dateEnd: x.dateEnd,
            genKwh: x.genKwh
          }
        })
        .sort((x, y) => {
          return x.dateStart > y.dateStart ? 1 : -1
        })
      const writebills = await getWriteBillsApi(
        this.$router.history.current.query.siteName,
        data
      )
      if (writebills?.data?.success) {
        await this.getbills()
      }
    }
  },
  async created () {
    await this.getbills()
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/customVariables.scss';
.dataTable {
  th {
    padding: 3px;
    min-width: 300px;
    border: solid 1px rgb(236, 236, 236);
  }

  td {
    padding: 5px 10px;
    font-size: 17px;
    border: solid 1px rgb(236, 236, 236);
  }
}

.highChartTitle {
  color: $c-dark-grey;
  font-size: 24px;
  letter-spacing: 2px;
}
</style>
