<template>
  <div>
    <div class="borderColor borderColorBackground my-3">
      <div class="d-flex align-items-center m-3">
        <b-form-checkbox-group
          v-model="dataClassArr"
          :options="dataClass"
          switches
        />
        <button
          class="confirmButton ml-auto"
          style="width: 120px"
          @click="downloadAnalysis"
        >
          資料下載
        </button>
      </div>
      <div
        v-if="hasNoData"
        class="bg-white text-center"
        style="line-height: 350px"
      >
        尚無資料
      </div>
      <highcharts
        v-else
        ref="chart"
        :options="prepareRateChart"
        class="bg-white"
      />
    </div>
    <div class="borderColor">
      <b-table
        :items="availability"
        :fields="tableField"
        hover
        :responsive="true"
        :small="true"
        class="tableStyle"
      >
        <template #cell()="data">
          {{ data.value === "" ? "-" : data.value }}
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import downloadCsv from 'download-csv'
import { dbDataHandle } from '../../store/function/common/index'
import { BTable } from 'bootstrap-vue'
import { getAvailabilityApi } from '../../api'
export default {
  name: 'PrepareRate',
  components: {
    BTable
  },
  props: {
    filename: {
      type: String,
      default: '下載檔案'
    }
  },
  data () {
    return {
      hasNoData: false,
      availability: [],
      tableField: [
        { key: 'date', label: '月份' },
        { key: 'availabilityRateActual', label: '妥善率實際值 (%)' },
        { key: 'availabilityRateGoal', label: '妥善率年度目標值 (%)' },
        { key: 'accAvaiActual', label: '妥善率累積實際值 (%)' }
      ],
      dataClassArr: ['妥善率實際值', '妥善率年度目標值', '妥善率累積實際值'],
      dataClass: ['妥善率實際值', '妥善率年度目標值', '妥善率累積實際值'],
      prepareRateChart: {
        chart: {
          height: 350,
          backgroundColor: null,
          events: {
            load () {
              this.showLoading()
            }
          }
        },
        credits: {
          enabled: false
        },
        title: {
          text: ''
        },
        xAxis: {
          categories: []
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true
            }
          }
        },
        legend: {
          layout: 'horizontal',
          align: 'center',
          verticalAlign: 'top',
          floating: false
        },
        yAxis: [
          {
            title: {
              text: '妥善率 (%)'
            }
          }
        ],
        tooltip: {
          shared: true
        },
        series: [
          {
            color: 'rgb(124,181,236)',
            type: 'column',
            name: '妥善率實際值 (%)',
            data: [],
            yAxis: 0,
            visible: true
          },
          {
            color: 'rgb(255, 192, 0)',
            name: '妥善率年度目標值 (%)',
            data: [],
            yAxis: 0,
            visible: true
          },
          {
            color: 'rgb(37, 84, 128)',
            name: '妥善率累積實際值 (%)',
            data: [],
            yAxis: 0,
            visible: true
          }
        ],
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500
              },
              chartOptions: {
                legend: {
                  layout: 'horizontal',
                  align: 'center',
                  verticalAlign: 'bottom',
                  y: 0
                }
              }
            }
          ]
        }
      },
      availabilityForExcel: []
    }
  },
  methods: {
    async getAvailability (months) {
      this.prepareRateChart.series.forEach((x) => {
        x.data = []
      })
      const availability = await getAvailabilityApi(
        this.$router.history.current.query.fid,
        this.$router.history.current.query.siteName + '.json',
        months
      )
      if (availability?.data?.success) {
        // 複製 availability、availabilityForExcel 不能 refer 同個 data
        // renderData() 會改動 availability 資料

        // 下載資料用
        this.availabilityForExcel = availability.data.data

        // 顯示圖表、表格用，copy 避免改動到 rawData (availability.data.data)
        this.availability = availability.data.data.map((item) => {
          const copy = Object.assign({}, item)
          return copy
        })

        this.renderData()
      } else {
        this.hasNoData = true
      }
    },
    renderData () {
      this.prepareRateChart.xAxis.categories = []
      this.prepareRateChart.series.forEach((x) => {
        x.data = []
      })
      this.availability.forEach((x) => {
        this.prepareRateChart.xAxis.categories.push(x.date)
        // 圖表、表格 是共用 this.availability 資料，直接 dbDataHandle 改變原始資料，顯示處理過的數據
        x.accAvaiActual =
          x.accAvaiActual === null ? null : dbDataHandle(x.accAvaiActual)
        x.availabilityRateActual =
          x.availabilityRateActual === null
            ? null
            : dbDataHandle(x.availabilityRateActual)
        x.availabilityRateGoal =
          x.availabilityRateGoal === null
            ? null
            : dbDataHandle(x.availabilityRateGoal)
        this.prepareRateChart.series[0].data.push(x.availabilityRateActual)
        this.prepareRateChart.series[1].data.push(x.availabilityRateGoal)
        this.prepareRateChart.series[2].data.push(x.accAvaiActual)
      })
      this.hideHighChartLoading()
    },
    hideHighChartLoading () {
      this.$nextTick(function () {
        if (this.$refs && this.$refs.chart) {
          this.$refs.chart.chart.hideLoading()
          this.prepareRateChart.chart.events = {}
        }
      })
    },
    downloadAnalysis () {
      const downloadArr = []
      let fields = ['name']
      fields = fields.concat(this.prepareRateChart.xAxis.categories)
      this.prepareRateChart.series.forEach((x) => {
        downloadArr.push({ name: x.name })
      })

      const download = []
      this.prepareRateChart.series.forEach((_) => {
        download.push({ data: [] })
      })

      this.availabilityForExcel.forEach((obj) => {
        const availabilityRateActual =
          obj.availabilityRateActual === null
            ? null
            : obj.availabilityRateActual
        const availabilityRateGoal =
          obj.availabilityRateGoal === null ? null : obj.availabilityRateGoal
        const accAvaiActual =
          obj.accAvaiActual === null ? null : obj.accAvaiActual
        download[0].data.push(availabilityRateActual)
        download[1].data.push(availabilityRateGoal)
        download[2].data.push(accAvaiActual)
      })

      this.prepareRateChart.xAxis.categories.forEach((x, xindex) => {
        downloadArr.forEach((y, yindex) => {
          y[`${x}`] = download[yindex].data[xindex]
        })
      })

      downloadCsv(downloadArr, fields, this.filename)
    },
    avgPerpareRate () {
      this.prepareRateChart.series[2].data = []
      let num = 0
      this.prepareRateChart.series[0].data.forEach((x, idx) => {
        num = num + x
        const avg = dbDataHandle(num / (idx + 1))
        this.prepareRateChart.series[2].data.push(avg)
      })
    }
  },
  watch: {
    dataClassArr: function (newValue, oldValue) {
      const setData = [
        {
          name: '妥善率實際值',
          dataName: ['妥善率實際值 (%)']
        },
        {
          name: '妥善率年度目標值',
          dataName: ['妥善率年度目標值 (%)']
        },
        {
          name: '妥善率累積實際值',
          dataName: ['妥善率累積實際值 (%)']
        }
      ]
      if (oldValue !== newValue) {
        this.prepareRateChart.series.forEach((x) => {
          x.visible = false
        })
        setData.forEach((x) => {
          if (newValue.includes(x.name)) {
            x.dataName.forEach((y) => {
              this.prepareRateChart.series.find(
                (z) => z.name === y
              ).visible = true
            })
          }
        })
      }
    }
  },
  async mounted () {
    this.avgPerpareRate()
  }
}
</script>
