<template>
  <div>
    <div class="borderColor borderColorBackground my-3">
      <div class="d-flex align-items-center m-3">
        <b-form-checkbox-group
          v-model="dataClassArr"
          :options="dataClass"
          switches
        />
        <button
          class="confirmButton ml-auto"
          style="width: 120px"
          @click="downloadAnalysis"
        >
          資料下載
        </button>
      </div>
      <div
        v-if="hasNoData"
        class="bg-white text-center"
        style="line-height: 350px"
      >
        尚無資料
      </div>
      <highcharts
        v-else
        ref="chart"
        :options="PrAnalysisChart"
        class="bg-white"
      />
    </div>
    <div class="borderColor">
      <b-table
        :items="prData"
        :fields="tableField"
        hover
        :responsive="true"
        :small="true"
        class="tableStyle"
      >
        <template #cell()="data">
          {{ data.value === "" ? "-" : data.value }}
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import downloadCsv from 'download-csv'
import { dbDataHandle } from '../../store/function/common/index'
import { BTable } from 'bootstrap-vue'
import { getPerformantRateApi } from '../../api'
export default {
  name: 'PR',
  components: {
    BTable
  },
  props: {
    filename: {
      type: String,
      default: '下載檔案'
    }
  },
  data () {
    return {
      hasNoData: false,
      prData: [],
      tableField: [
        { key: 'date', label: '月份' },
        { key: 'PRGoal', label: '目標 PR 值 (%)' },
        { key: 'PRActual', label: '實際 PR 值 (%)' },
        { key: 'guaranteePr', label: '保證 PR 值 (%)' },
        { key: 'yearPRGoal', label: '年度目標值 (%)' },
        { key: 'accPRAll', label: '累積實際值 (%)' },
        { key: 'accGuaranteePr', label: '(區間) 累積保證值 (%)' }
      ],
      dataClassArr: [
        '目標 PR 值 (%)',
        '實際 PR 值 (%)',
        '保證 PR 值 (%)',
        '年度目標值 (%)',
        '累積實際值 (%)',
        '(區間) 累積保證值 (%)'
      ],
      dataClass: [
        '目標 PR 值 (%)',
        '實際 PR 值 (%)',
        '保證 PR 值 (%)',
        '年度目標值 (%)',
        '累積實際值 (%)',
        '(區間) 累積保證值 (%)'
      ],
      PrAnalysisChart: {
        chart: {
          height: 350,
          backgroundColor: null,
          events: {
            load () {
              this.showLoading()
            }
          }
        },
        credits: {
          enabled: false
        },
        title: {
          text: ''
        },
        xAxis: {
          categories: []
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true
            }
          }
        },
        legend: {
          layout: 'horizontal',
          align: 'center',
          verticalAlign: 'top',
          floating: false
        },
        yAxis: [
          {
            title: {
              text: 'PR值 (%)'
            }
          }
        ],
        tooltip: {
          shared: true
        },
        series: [
          {
            color: 'rgb(255, 192, 0)',
            type: 'column',
            name: '目標 PR 值 (%)',
            data: [],
            yAxis: 0,
            visible: true
          },
          {
            color: 'rgb(124,181,236)',
            type: 'column',
            name: '實際 PR 值 (%)',
            data: [],
            yAxis: 0,
            visible: true
          },
          {
            color: 'rgb(31, 209, 150)',
            type: 'column',
            name: '保證 PR 值 (%)',
            data: [],
            yAxis: 0,
            visible: true
          },
          {
            color: 'rgb(255, 162, 0)',
            name: '年度目標值 (%)',
            data: [],
            yAxis: 0,
            visible: true
          },
          {
            color: 'rgb(86, 160, 232)',
            name: '累積實際值 (%)',
            data: [],
            yAxis: 0,
            visible: true
          },
          {
            color: 'rgb(2, 181, 122)',
            name: '(區間) 累積保證值 (%)',
            data: [],
            yAxis: 0,
            visible: true
          }
        ],
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500
              },
              chartOptions: {
                legend: {
                  layout: 'horizontal',
                  align: 'center',
                  verticalAlign: 'bottom',
                  y: 0
                }
              }
            }
          ]
        }
      },
      prDataForExcel: []
    }
  },
  methods: {
    async getPrData (months) {
      this.PrAnalysisChart.series.forEach((x) => {
        x.data = []
      })
      const prData = await getPerformantRateApi(
        this.$router.history.current.query.fid,
        this.$router.history.current.query.siteName + '.json',
        months
      )
      if (prData?.data?.success) {
        // 複製 prData、prDataForExcel 不能 refer 同個 data
        // renderData() 會改動 prData 資料

        // 下載資料用
        this.prDataForExcel = prData.data.data

        // 顯示圖表、表格用，copy 避免改動到 rawData (etodayData.data.data)
        this.prData = prData.data.data.map((item) => {
          const copy = Object.assign({}, item)
          return copy
        })

        this.renderData()
      } else {
        this.hasNoData = true
      }
    },
    renderData () {
      this.PrAnalysisChart.xAxis.categories = []
      this.PrAnalysisChart.series.forEach((x) => {
        x.data = []
      })
      this.prData.forEach((x) => {
        this.PrAnalysisChart.xAxis.categories.push(x.date)
        // 圖表、表格 是共用 this.prData 資料，直接 dbDataHandle 改變原始資料，顯示處理過的數據
        x.PRGoal = x.PRGoal === null ? null : dbDataHandle(x.PRGoal)
        x.PRActual =
          x.PRActual === null ? null : dbDataHandle(x.PRActual * 100)
        // x.PRActual = x.PRActual === null ? null : dbDataHandle(x.PRActual * 100) > 100 ? 100 : dbDataHandle(x.PRActual * 100)
        x.guaranteePr =
          x.guaranteePr === null ? null : dbDataHandle(x.guaranteePr)
        x.yearPRGoal =
          x.yearPRGoal === null ? null : dbDataHandle(x.yearPRGoal)
        x.accPRAll =
          x.accPRAll === null ? null : dbDataHandle(x.accPRAll * 100)
        x.accGuaranteePr =
          x.accGuaranteePr === null ? null : dbDataHandle(x.accGuaranteePr)
        this.PrAnalysisChart.series[0].data.push(x.PRGoal)
        this.PrAnalysisChart.series[1].data.push(x.PRActual)
        this.PrAnalysisChart.series[2].data.push(x.guaranteePr)
        this.PrAnalysisChart.series[3].data.push(x.yearPRGoal)
        this.PrAnalysisChart.series[4].data.push(x.accPRAll)
        this.PrAnalysisChart.series[5].data.push(x.accGuaranteePr)
      })
      this.hideHighChartLoading()
      // this.avgPR()
    },
    hideHighChartLoading () {
      this.$nextTick(function () {
        if (this.$refs && this.$refs.chart) {
          this.$refs.chart.chart.hideLoading()
          this.PrAnalysisChart.chart.events = {}
        }
      })
    },
    downloadAnalysis () {
      const downloadArr = []
      let fields = ['name']
      fields = fields.concat(this.PrAnalysisChart.xAxis.categories)
      this.PrAnalysisChart.series.forEach((x) => {
        downloadArr.push({ name: x.name })
      })

      const download = []
      this.PrAnalysisChart.series.forEach((_) => {
        download.push({ data: [] })
      })

      this.prDataForExcel.forEach((obj) => {
        const PRGoal = obj.PRGoal === null ? null : obj.PRGoal
        const PRActual = obj.PRActual === null ? null : obj.PRActual * 100
        const guaranteePr = obj.guaranteePr === null ? null : obj.guaranteePr
        const yearPRGoal = obj.yearPRGoal === null ? null : obj.yearPRGoal
        const accPRAll = obj.accPRAll === null ? null : obj.accPRAll * 100
        const accGuaranteePr =
          obj.accGuaranteePr === null ? null : obj.accGuaranteePr
        download[0].data.push(PRGoal)
        download[1].data.push(PRActual)
        download[2].data.push(guaranteePr)
        download[3].data.push(yearPRGoal)
        download[4].data.push(accPRAll)
        download[5].data.push(accGuaranteePr)
      })

      this.PrAnalysisChart.xAxis.categories.forEach((x, xindex) => {
        downloadArr.forEach((y, yindex) => {
          y[`${x}`] = download[yindex].data[xindex]
        })
      })
      downloadCsv(downloadArr, fields, this.filename)
    },
    avgPR () {
      this.PrAnalysisChart.series[3].data = []
      let num = 0
      this.PrAnalysisChart.series[1].data.forEach((x, idx) => {
        num = num + x
        const avg = dbDataHandle(num / (idx + 1))
        this.PrAnalysisChart.series[3].data.push(avg)
      })
    }
  },
  watch: {
    dataClassArr: function (newValue, oldValue) {
      const setData = [
        {
          name: '目標 PR 值 (%)',
          dataName: ['目標 PR 值 (%)']
        },
        {
          name: '實際 PR 值 (%)',
          dataName: ['實際 PR 值 (%)']
        },
        {
          name: '保證 PR 值 (%)',
          dataName: ['保證 PR 值 (%)']
        },
        {
          name: '年度目標值 (%)',
          dataName: ['年度目標值 (%)']
        },
        {
          name: '累積實際值 (%)',
          dataName: ['累積實際值 (%)']
        },
        {
          name: '(區間) 累積保證值 (%)',
          dataName: ['(區間) 累積保證值 (%)']
        }
      ]
      if (oldValue !== newValue) {
        this.PrAnalysisChart.series.forEach((x) => {
          x.visible = false
        })
        setData.forEach((x) => {
          if (newValue.includes(x.name)) {
            x.dataName.forEach((y) => {
              this.PrAnalysisChart.series.find(
                (z) => z.name === y
              ).visible = true
            })
          }
        })
      }
    }
  },
  async mounted () {
    this.avgPR()
  }
}
</script>
